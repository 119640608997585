<template>
	<!-- 售后订单 -->
	<div class="order-sale">
		<page-arbitration></page-arbitration>
	</div>
</template>

<script>
	import pageArbitration from '@/components/layout/goods/page-arbitration.vue'
	export default{
		components:{
			pageArbitration
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss">
</style>
